import apiClient from '@/services/axios'

export const getExaminations = async (query) => {
  const res = await apiClient.get(`/api/pemeriksaan`)
  if (res) {
    return res
  } else {
    return null
  }
}
export const getExaminationById = async (id) => {
  const res = await apiClient.get(`/api/pemeriksaan/` + id)
  if (res) {
    return res
  } else {
    return null
  }
}

export const getExaminationsCategories = async (query) => {
  const res = await apiClient.get(`/api/pemeriksaan/categories`)
  if (res) {
    return res
  } else {
    return null
  }
}

export const getLoincCodeAndAnswer = async (query) => {
  const res = await apiClient.get(`/api/pemeriksaan/loincCode`)
  if (res) {
    return res
  } else {
    return null
  }
}
export const getSpecimentSourceData = async (query) => {
  const res = await apiClient.get(`/api/pemeriksaan/speciment-source-data`)
  if (res) {
    return res
  } else {
    return null
  }
}

export const createExamination = async (master, components) => {
  const res = await apiClient.post(`/api/pemeriksaan`, { master, components })
  if (res) {
    return res
  } else {
    return null
  }
}
export const updateExamination = async (master, components, id) => {
  const res = await apiClient.put(`/api/pemeriksaan/${id}`, {
    master,
    components,
  })
  if (res) {
    return res
  } else {
    return null
  }
}

export const deleteExaminations = async (query, id, status) => {
  const res = await apiClient.delete(`/api/pemeriksaan`, {
    params: { query, id, status },
  })
  if (res) {
    return res
  } else {
    return null
  }
}

export const getExaminationRequest = async () => {
  const res = await apiClient.get(`/api/pemeriksaan/request`)
  if (res) {
    return res
  } else {
    return null
  }
}

export const getExaminationRequestById = async (id) => {
  const res = await apiClient.get(`/api/pemeriksaan/request/` + id)
  if (res) {
    return res
  } else {
    return null
  }
}

export const createExaminationSpecimen = async (
  idExaminationRequest,
  specimens,
) => {
  const res = await apiClient.post(`/api/pemeriksaan/specimen`, {
    idExaminationRequest,
    specimens,
  })
  if (res) {
    return res
  } else {
    return null
  }
}

export const createExaminationResult = async (
  idExaminationRequest,
  examinationResults,
) => {
  const res = await apiClient.post(`/api/pemeriksaan/result`, {
    idExaminationRequest,
    examinationResults,
  })
  if (res) {
    return res
  } else {
    return null
  }
}
export const createExaminationRequest = async (idVisit, examinationsData) => {
  const res = await apiClient.post(`/api/pemeriksaan/request`, {
    idVisit,
    examinationsData,
  })
  if (res) {
    return res
  } else {
    return null
  }
}
export const automaticInputExamination = async (formData, components) => {
  try {
    // console.log('disiniadangga')
    // console.log(formData, '<<<<<')
    formData.append('components', JSON.stringify(components))

    const res = await apiClient.post(
      `/api/pemeriksaan/automatic-examination`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // Wajib untuk FormData
        },
      },
    )
    return res.data
  } catch (error) {
    console.error('Error saat mengirim file ke backend:', error)
    throw error
  }
}
